import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Frown, Home } from "react-feather"


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="top-part">
      <div id="left-col">
        <div id="left-col-content">
          <div className="page-icon-container">
            <Frown className="page-icon" />
          </div>
          <h1 id="community-header">You Broke It.</h1>
          <h2 className="description">You're looking for something that doesn&#39;t exist here. How about we take you back home?</h2>

        </div>
      </div>
      <div id="right-col">
      
        <AniLink fade to="/" duration={0.4}>
          <div className="long-container slack">
            
            <div className="item-header">
            <div className="long-icon-container">
                <Home className="slack-icon"/> 
              </div>
            Yes, Take me Home
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </AniLink>
      
    
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
